import React, { useState } from "react";
import { mapFormDataToCustomer, mergeErrors } from "../../api/utils";
import { useMutation, useQuery } from "@apollo/client";

import Alert from "react-bootstrap/Alert";
import { CustomerCreateForm } from "../forms/CustomerCreateForm";
import { MUTATION_CREATE_CUSTOMER } from "../../api/mutations";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { QUERY_ALL_REFERRAL_SOURCES } from "../..//api/queries";
import Row from "react-bootstrap/Row";
import { controlTaxiId } from "../../api/utils";
import { extractError } from "../ErrorMessage";

function CustomerCreateModal({
  show = false,
  onHide = () => {},
  defaultValues = {},
  onCreated = () => {},
  fullInfo = true,
  validation,
}) {
  // Mutation
  const [
    createCustomerMutation,
    {
      loading: createCustomerLoading,
      error: createCustomerError,
      data: createCustomerData,
    },
  ] = useMutation(MUTATION_CREATE_CUSTOMER);

  // Query
  const { error: referralSourcesError, data: referralSourcesData } = useQuery(
    QUERY_ALL_REFERRAL_SOURCES
  );

  const [submitError, setSubmitError] = useState(null);
  const [msgValue, setMsgValue] = useState("");
  const reportedErrors = extractError([
    referralSourcesError,
    createCustomerError,
    createCustomerData?.createCustomer?.error,
    submitError,
  ]);
  async function onSubmit(formData) {
    setMsgValue(""); //erase previus error
    const resultTaxiId = controlTaxiId(
      formData.taxId,
      formData.first,
      formData.last
    );
    if (resultTaxiId !== "") {
      setMsgValue(<Alert variant="danger"> {resultTaxiId} </Alert>);
      return;
    }

    // Check if tax ID has been validated
    if (!formData.taxIdVerified && !!formData.taxId) {
      setMsgValue(
        <>
          <div className="mt-4" />
          <Alert variant="danger">
            Verificare il codice fiscale prima di procedere
          </Alert>
        </>
      );
      setTimeout(() => {
        setMsgValue("");
      }, 5000); // 5 seconds
      return;
    }

    const customer = mapFormDataToCustomer(formData);
    try {
      const result = await createCustomerMutation({
        variables: {
          customer: {
            ...customer,
            activateOnCreation: true,
          },
        },
      });
      const errors = mergeErrors([
        result?.errors,
        result?.data?.createCustomer?.error,
      ]);
      if (!errors) {
        // Only calls onCreated if not errors
        onCreated(result?.data?.createCustomer?.customer);
      }
    } catch (e) {
      setSubmitError(e);
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Nuovo Cliente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomerCreateForm
          onSubmit={onSubmit}
          creating={createCustomerLoading}
          errors={reportedErrors}
          completed={!!createCustomerData}
          defaultValues={defaultValues}
          referralSourcesList={referralSourcesData?.referralSources}
          validation={validation}
          fullInfo={fullInfo}
        />
        <Row>{msgValue !== "" ? msgValue : null}</Row>
      </Modal.Body>
    </Modal>
  );
}

CustomerCreateModal.propTypes = {
  // Show / hide modal
  show: PropTypes.bool,
  // Triggered when hide button is tapped
  onHide: PropTypes.func,
  // Fomr values to preload
  defaultValues: PropTypes.object,
  onCreated: PropTypes.func,
};

export default CustomerCreateModal;
