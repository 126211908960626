import { subscriptionItalianState } from "../api/utils";
import { diffDays } from "../api/date-utils";
import {
  getCustomerNewCheckInUrl,
  getCustomerUrl,
  getPlanUrl,
  getSubscriptionCheckinsListUrl,
  getSubscriptionDetailUrl,
  getSubscriptionInvoicesListUrl,
} from "../api/urls";

import ActionsButton from "../components/ActionsButton";
import { ErrorMessage } from "../components/ErrorMessage";
import { QUERY_LIST_SUBSCRIPTION_PAGINATOR } from "../api/queries";
import React from "react";
import SubscriptionsList from "../components/SubscriptionsList";
import constants from "../constants";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";

function loadAllSubscription(subscriptionList, page, pageSize) {
  let j = (page - 1) * pageSize;
  const arrayListSubscription = subscriptionList.map((s) => {
    const l = s?.subscription;
    const customerName = l?.customer?.fullName;
    const invoiceCustomerName =
      l?.invoicingCustomer?.uuid === l?.customer?.uuid
        ? ""
        : `(${l?.invoicingCustomer?.fullName})`;

    const checkinCount = s?.checkinCount === "0" ? "--" : s?.checkinCount;
    const lastCheckinDate = s?.lastCheckin;
    const passedDays = !!lastCheckinDate
      ? diffDays(new Date(lastCheckinDate), new Date())
      : "";

    let lastCheckin = !!lastCheckinDate
      ? `${lastCheckinDate} (${passedDays} gg)`
      : "";

    // Check if CheckIn is today
    lastCheckin =
      passedDays === 0 && !!lastCheckinDate ? lastCheckinDate : lastCheckin;

    j = j + 1;

    return {
      startDate: l?.startDate,
      planName: l?.plan.name,
      checkinCount: checkinCount,
      paymentType: l?.paymentType,
      customerName: customerName,
      invoiceCustomerName: invoiceCustomerName,
      subscriptionUuid: l?.uuid,
      invoicingUuid: l?.invoicingCustomer?.uuid,
      customerUuid: l?.customer?.uuid,
      planUuid: l?.plan?.uuid,
      needsUpdate: l?.customer?.needsUpdate,
      stateIt: subscriptionItalianState[l?.state],
      state: l?.state,
      lastCheckIn: lastCheckin,
      index: j,
      menu: "•••",
    };
  });

  return arrayListSubscription;
}

function SubscriptionsListPage() {
  const defaultOrder = "-start_date"; //if "" is set, the order is established by resolver
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const page = parseInt(urlParams.get("page"));
  let numPage = !!page ? page : 1;

  let filter =
    urlParams.get("filter") === null
      ? `state:${constants.SUBSCRIPTION_STATE_ACTIVE}`
      : urlParams.get("filter");

  let order =
    urlParams.get("order") === null ? defaultOrder : urlParams.get("order");
  const paramVar = order === "" ? filter : `${filter}:${order}`;

  const PAGESIZE = 50; //set row for Page

  const { loading, error, data } = useQuery(QUERY_LIST_SUBSCRIPTION_PAGINATOR, {
    variables: {
      page: numPage,
      pageSize: PAGESIZE,
      parameter: paramVar,
    },
  });

  const popoverLinksList = (cell) => {
    let linksList = [];
    const enabled =
      cell?.state === constants.SUBSCRIPTION_STATE_ACTIVE ||
      cell?.state === constants.SUBSCRIPTION_STATE_PAUSED;
    const showDisplayNewCheckin =
      !cell?.needUpdate && enabled && !!cell?.customerUuid;
    const showListPayment =
      cell?.paymentType?.toUpperCase() === constants.STRIPE_PAYMENT_METHOD;
    const showListCheckIn = cell?.checkinCount !== "--";

    const showDetailInvoiceCustomer = cell?.invoiceCustomerName !== "";

    if (showDisplayNewCheckin) {
      linksList.push({
        title: "Nuovo Check-In",
        url: getCustomerNewCheckInUrl(cell?.customerUuid),
      });
    }

    if (showListPayment) {
      linksList.push({
        title: "Visualizza Elenco Pagamenti",
        url: getSubscriptionInvoicesListUrl(cell?.subscriptionUuid),
      });
    }

    if (showListCheckIn) {
      linksList.push({
        title: "Visualizza Elenco Check-In",
        url: getSubscriptionCheckinsListUrl(cell?.subscriptionUuid),
      });
    }

    if (showDetailInvoiceCustomer) {
      linksList.push({
        title: "Visualizza Cliente Titolare",
        url: getCustomerUrl(cell?.invoicingUuid),
      });
    }

    linksList = [
      ...linksList,
      {
        title: "Visualizza Abbonamento",
        url: getSubscriptionDetailUrl(cell?.subscriptionUuid),
      },
      {
        title: "Visualizza Cliente Beneficiario",
        url: getCustomerUrl(cell?.customerUuid),
      },
      {
        title: "Visualizza Piano",
        url: getPlanUrl(cell?.planUuid),
      },
    ];

    return linksList;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            N.
          </div>
        ),
        accessor: "index",
        width: 20,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Data Inizio",
        accessor: "startDate",
        width: 65,
      },
      {
        Header: "Piano",
        accessor: "planName",
        width: 110,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Numero Check-In
          </div>
        ),
        accessor: "checkinCount",
        width: 45,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Metodo Pagamento
          </div>
        ),
        accessor: "paymentType",
        width: 50,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div>
            Beneficiario
            <div>(Titolare) </div>
          </div>
        ),
        accessor: "customerName",
        width: 110,
        Cell: ({ cell }) => (
          <div>
            {cell?.row?.original.customerName}
            <div>{cell?.row?.original.invoiceCustomerName}</div>
          </div>
        ),
      },
      {
        Header: "Ultimo CheckIn",
        accessor: "lastCheckIn",
        width: 90,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Stato
          </div>
        ),
        accessor: "stateIt",
        width: 40,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "",
        accessor: "menu",
        width: 25,
        Cell: ({ cell }) => {
          const links = popoverLinksList(cell?.row?.original);
          return <ActionsButton linksList={links} />;
        },
      },
    ],
    []
  );

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }
  //

  const subscriptionList = data?.subscriptionPaginator?.listSubscription;
  const listSubscriptions = loadAllSubscription(
    subscriptionList,
    numPage,
    PAGESIZE
  );

  let planList = [];
  planList.push({
    key: "0",
    plan: "Tutti",
  });
  for (var j in data?.allPlans) {
    planList.push({
      key: data.allPlans[j]?.uuid,
      plan: data.allPlans[j]?.name,
    });
  }
  return (
    <SubscriptionsList
      data={listSubscriptions}
      columns={columns}
      paginator={data?.paginator}
      filter={filter}
      order={order}
      planList={planList}
    />
  );
}

export default SubscriptionsListPage;
