import { allCounties, loadCitiesForCounty } from "../api/utils";
import { controlTaxiId, checkTaxiId, isForeignTaxId } from "../api/utils";
import constants from "../constants";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import PhoneNumber from "./PhoneNumber";
import PropTypes from "prop-types";
import React from "react";
import Row from "react-bootstrap/Row";
import { useFormContext } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useLazyQuery } from "@apollo/client";
import { VERIFY_FISCAL_CODE, GET_FEATURE_FLAGS } from "../api/queries";
import { useState, useEffect, useCallback } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const countyList = allCounties();

// Modal component for fiscal code verification
const VerifyModal = ({
  show,
  onHide,
  data,
  loading,
  error,
  validationError,
  showForeignCheckbox,
  isForeignWithoutCF,
  onForeignCheckboxChange,
  onVerify,
  showManualVerificationMessage,
}) => {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Verifica Codice Fiscale</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div>Verifica in corso...</div>}
        {showManualVerificationMessage && (
          <div className="text-info">
            Il sistema di verifica momentaneamente non è disponibile. Utilizzare
            la verifica con l'Agenzia delle Entrate
          </div>
        )}
        {validationError && (
          <div className="text-danger">{validationError}</div>
        )}
        {showForeignCheckbox && (
          <Form.Group className="mt-3">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px", // Left margin of container
              }}
            >
              <input
                type="checkbox"
                id="foreignCheckbox"
                checked={isForeignWithoutCF}
                onChange={(e) => {
                  e.stopPropagation();
                  onForeignCheckboxChange(e.target.checked);
                }}
                style={{
                  width: "1.2em",
                  height: "1.2em",
                  border: "2px solid #333",
                  borderRadius: "0.25em",
                  marginRight: "0.5em",
                  cursor: "pointer", // Adds pointer cursor on hover
                }}
              />
              <label
                htmlFor="foreignCheckbox"
                style={{ cursor: "pointer" }} // Also adds pointer cursor to label
              >
                È uno straniero senza CF?
              </label>
            </div>
          </Form.Group>
        )}
        {error && <div className="text-danger">{error.message}</div>}
        {data?.verifyFiscalCode && (
          <div
            className={
              data.verifyFiscalCode.valid ? "text-success" : "text-danger"
            }
          >
            {data.verifyFiscalCode.error || data.verifyFiscalCode.message}
          </div>
        )}

        {!loading &&
          !error &&
          !data &&
          !validationError &&
          !showManualVerificationMessage && (
            <div>Inserire un codice fiscale valido</div>
          )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            if (showManualVerificationMessage || isForeignWithoutCF) {
              onVerify(true);
            }
            onHide();
          }}
        >
          Chiudi
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Main component for customer input form
function CustomerInput({ fullInfo, prefix = "" }) {
  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const watchCounty = watch("county");
  const availableCities = loadCitiesForCounty(watchCounty);

  const prefixer = useCallback((name) => `${prefix}${name}`, [prefix]);

  const [showModal, setShowModal] = React.useState(false);

  const [verifyFiscalCode, { data, loading, error }] = useLazyQuery(
    VERIFY_FISCAL_CODE,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setShowModal(true);
      },
      onError: (error) => {
        console.error("Errore nella query:", error);
        setShowModal(true);
      },
    }
  );

  const [getFeatureFlag] = useLazyQuery(GET_FEATURE_FLAGS, {
    variables: { name: constants.FEATURE_FLAG_FOR_VERIFY_FISCAL_CODE },
  });

  const [isVerified, setIsVerified] = useState(false);
  const [showForeignCheckbox, setShowForeignCheckbox] = useState(false);
  const [isForeignWithoutCF, setIsForeignWithoutCF] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationSuccessful, setVerificationSuccessful] = useState(false);
  const [showManualVerificationMessage, setShowManualVerificationMessage] =
    useState(false);

  useEffect(() => {
    setValue(prefixer("taxIdVerified"), isVerified);
  }, [isVerified, setValue, prefixer]);

  // Handle foreign customer checkbox changes
  const handleForeignCheckboxChange = (checked) => {
    setIsForeignWithoutCF(checked);
  };

  // Verify tax ID with backend and perform local validations
  const handleVerifyTaxId = async () => {
    setIsVerifying(true);
    setValidationError(null);
    setShowForeignCheckbox(false);
    setIsForeignWithoutCF(false);
    setVerificationSuccessful(false);
    setValue(prefixer("taxIdVerified"), false);
    setShowManualVerificationMessage(false);

    try {
      const taxId = watch(prefixer("taxId"));
      const firstName = watch(prefixer("first"));
      const lastName = watch(prefixer("last"));

      if (!taxId) {
        setValidationError("Il codice fiscale è obbligatorio");
        setShowModal(true);
        return;
      }

      // Check if it's a foreign tax ID
      const isForiegn = isForeignTaxId(taxId.trim());

      // Preliminary check of tax ID with first and last name
      const validationResult = controlTaxiId(taxId.trim(), firstName, lastName);

      if (!!validationResult) {
        setValidationError(validationResult);
        setShowForeignCheckbox(isForiegn);
        setShowModal(true);
        return;
      }

      // Validate tax ID format
      const resultCheckTaxiId = checkTaxiId(taxId.trim());
      if (!resultCheckTaxiId) {
        setValidationError("Codice fiscale errato");
        setShowForeignCheckbox(isForiegn);
        setShowModal(true);
        return;
      }

      // Modify feature flag logic
      const featureFlagResult = await getFeatureFlag();
      if (!featureFlagResult.data?.featureFlagByName?.enabled) {
        setShowManualVerificationMessage(true);
        setShowModal(true);
        return;
      }

      const result = await verifyFiscalCode({
        variables: {
          fiscalCode: taxId.trim(),
        },
      });

      if (result.data?.verifyFiscalCode?.valid) {
        setVerificationSuccessful(true);
        setValue(prefixer("taxIdVerified"), true);

        // Set timer to auto-close modal after 10 seconds
        setTimeout(() => {
          setShowModal(false);
        }, 5000);
      } else {
        setVerificationSuccessful(false);
        setShowForeignCheckbox(isForiegn);
        setShowModal(true);
      }
    } catch (err) {
      console.error("Error during verification:", err);
      setValidationError(err.message);
      setShowModal(true);
      setVerificationSuccessful(false);
      setValue(prefixer("taxIdVerified"), false);
    } finally {
      setIsVerifying(false);
    }
  };

  // Dynamic address input component
  let AddressInput = (
    <>
      <Row>
        <Form.Group as={Col} controlId="fieldRoad">
          <CustomerInputField
            label="Via"
            name={prefixer("road")}
          ></CustomerInputField>
        </Form.Group>
        <Form.Group as={Col} controlId="fieldHouseNumber">
          <CustomerInputField
            label="Numero Civico"
            name={prefixer("houseNumber")}
            maxLength="8"
          ></CustomerInputField>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="fieldCounty">
          <CustomerInputField
            label="Provincia"
            name={prefixer("county")}
            as="select"
          >
            {countyList.map(({ code, name }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </CustomerInputField>
        </Form.Group>
        <Form.Group as={Col} controlId="fieldCity">
          <CustomerInputField label="Città" name={prefixer("city")} as="select">
            {availableCities.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </CustomerInputField>
        </Form.Group>
        <Form.Group as={Col} controlId="fieldZipCode">
          <CustomerInputField
            label="CAP"
            name={prefixer("postcode")}
            maxLength="5"
            onChange={(e) => {
              let digits_only = (string) =>
                [...string].every((c) => "0123456789".includes(c));
              let v = e.target.value;
              if (!digits_only(e.target.value)) {
                v = "";
              }
              setValue(prefixer("postcode"), v);
            }}
          ></CustomerInputField>
        </Form.Group>
      </Row>
    </>
  );

  // Hide address input if fullInfo is false
  if (!fullInfo) {
    AddressInput = null;
  }

  let disabledFieldTaxID = verificationSuccessful;
  if (showManualVerificationMessage) {
    disabledFieldTaxID = false;
  }

  return (
    <>
      <Row>
        <Form.Group as={Col} controlId="fieldFirstName">
          <CustomerInputField
            label="Nome"
            name={prefixer("first")}
            register={register}
            errors={errors}
          ></CustomerInputField>
        </Form.Group>
        <Form.Group as={Col} controlId="fieldLastName">
          <CustomerInputField
            label="Cognome"
            name={prefixer("last")}
            register={register}
            errors={errors}
          ></CustomerInputField>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="fieldEmail">
          <CustomerInputField
            label="Email"
            name={prefixer("email")}
            register={register}
            errors={errors}
          ></CustomerInputField>
        </Form.Group>
        <Form.Group as={Col} controlId="fieldTaxID" className="d-flex">
          <div className="flex-grow-1">
            <CustomerInputField
              label="Codice Fiscale"
              name={prefixer("taxId")}
              register={register}
              errors={errors}
              disabled={disabledFieldTaxID}
            />
          </div>
          <div className="d-flex align-items-end mb-3 ms-2">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>Clicca qui per la Verifica del Codice Fiscale</Tooltip>
              }
            >
              <Button
                variant={verificationSuccessful ? "success" : "outline-primary"}
                onClick={handleVerifyTaxId}
                disabled={verificationSuccessful || isVerifying}
              >
                {isVerifying ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "✓"
                )}
              </Button>
            </OverlayTrigger>
          </div>
        </Form.Group>
      </Row>
      <h6>Telefono</h6>
      <Row>
        <PhoneNumber control={control} errors={errors} prefix={prefix} />
      </Row>
      {AddressInput}

      <VerifyModal
        show={showModal}
        onHide={() => {
          if (isForeignWithoutCF || showManualVerificationMessage) {
            setVerificationSuccessful(true);
            setValue(prefixer("taxIdVerified"), true);
          }
          setShowModal(false);
          setValidationError(null);
          setShowForeignCheckbox(false);
        }}
        data={data}
        loading={loading}
        error={error}
        validationError={validationError}
        showForeignCheckbox={showForeignCheckbox}
        isForeignWithoutCF={isForeignWithoutCF}
        onForeignCheckboxChange={handleForeignCheckboxChange}
        onVerify={(verified) => {
          setIsVerified(verified);
          if (verified) {
            setVerificationSuccessful(true);
          }
        }}
        showManualVerificationMessage={showManualVerificationMessage}
      />

      <input
        type="hidden"
        {...register(prefixer("taxIdVerified"))}
        value={verificationSuccessful}
      />
    </>
  );
}

// Reusable form field component with validation
function CustomerInputField(props) {
  const {
    register,

    formState: { errors },
  } = useFormContext();

  let cascadeProps = { ...props };
  delete cascadeProps.errors;
  delete cascadeProps.register;

  return (
    <>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        type="text"
        name={props.name}
        placeholder={props.label}
        {...register(props.name)}
        isInvalid={!!errors[props.name]}
        {...cascadeProps}
      />
      <Form.Control.Feedback type="invalid">
        {errors[props.name] && errors[props.name].message}
      </Form.Control.Feedback>
    </>
  );
}

CustomerInput.propTypes = {
  fullInfo: PropTypes.bool,
  prefix: PropTypes.string,
};

export { CustomerInput, CustomerInputField };
