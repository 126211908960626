import React, { useState } from "react";
import { getCustomerCheckinsListUrl, getCustomerUrl } from "../api/urls";
import {
  isPreviousCheckinForCadence,
  loadListCheckIn,
  lookUpCadenceType,
} from "../api/utils";
import { useMutation, useQuery } from "@apollo/client";

import CustomerCheckInForm from "../components/CustomerCheckInForm";
import { ErrorMessage } from "../components/ErrorMessage";
import { MUTATION_CREATE_CHECK_IN } from "../api/mutations";
import { Navigate } from "react-router";
import { QUERY_CHECKIN_CUSTOMER } from "../api/queries";
import constants from "../constants";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export function loadTypeCheckIn(
  //cfs=allCheckinFundingSource
  //sub= customerSubscriptionsEnabled
  //ccr= customerCreditList
  cfs,
  sub,
  customerCreditList,
  customerPlanProducts,
  productsForFundingSource,
  customerPrepaidPackageList
) {
  var myr = [];
  var k;
  let checked = 0;
  const UNCHECKED = 0;
  const CHECKED = 1;
  for (const customerCredit in customerCreditList) {
    //Load credit
    let customerCreditK = customerCreditList[customerCredit].credit;
    const checkInAvailability = isPreviousCheckinForCadence(
      customerCreditK?.lastCheckin?.date,
      customerCreditK?.plan?.checkinCadence
    );
    const mapProducts = customerPlanProducts.filter(
      (customerPlanProducts) =>
        customerPlanProducts?.plan?.uuid === customerCreditK?.plan.uuid
    );
    const numberPrest = mapProducts.length;

    const typCheck =
      checked === UNCHECKED && numberPrest > 0 ? CHECKED : UNCHECKED;
    const remainingCheckins =
      customerCreditK?.sessionQuantity -
      customerCreditList[customerCredit].checkinCount;
    myr.push({
      type: constants.TYPE_CHECKINLIST_CREDIT,
      uuid: customerCreditK?.uuid,
      name: customerCreditK?.plan?.name,
      planUuid: customerCreditK?.plan?.uuid,
      zone: customerCreditK?.zonesQuantity,
      startDate: customerCreditK?.startDate,
      checkInAvailability: checkInAvailability,
      checkinCadence: lookUpCadenceType(customerCreditK?.plan?.checkinCadence),
      creditTypeName: customerCreditK.creditType.name,
      checked: typCheck,
      agreementContract: null,
      numberPrest: numberPrest,
      checkinPossible: true,
      invoices: null,
      state: customerCreditK?.state,
      remainingCheckins: remainingCheckins,
    });
    checked = checked === 0 ? typCheck : checked;
  }
  for (const customerPrepaidPakage in customerPrepaidPackageList) {
    //Load Prepaid Package
    let customerPrepaidPakageK =
      customerPrepaidPackageList[customerPrepaidPakage].PrepaidPackage;
    const checkInAvailability = isPreviousCheckinForCadence(
      customerPrepaidPakageK?.lastCheckin?.date,
      customerPrepaidPakageK?.packagePlan?.checkinCadence
    );
    const remainingCheckins =
      customerPrepaidPakageK?.sessionQuantity -
      customerPrepaidPackageList[customerPrepaidPakage].checkinCount;

    const numberPrest = 1;

    const typCheck =
      checked === UNCHECKED && numberPrest > 0 ? CHECKED : UNCHECKED;
    myr.push({
      type: constants.TYPE_CHECKINLIST_PREPAID_PACKAGE,
      uuid: customerPrepaidPakageK?.uuid,
      name: customerPrepaidPakageK?.packagePlan?.description,
      planUuid: customerPrepaidPakageK?.packagePlan?.uuid,
      zone: 9,
      startDate: customerPrepaidPakageK?.startDate,
      checkInAvailability: checkInAvailability,
      checkinCadence: lookUpCadenceType(
        customerPrepaidPakageK?.packagePlan?.checkinCadence
      ),
      creditTypeName: null,
      checked: typCheck,
      agreementContract: null,
      numberPrest: numberPrest,
      checkinPossible: true,
      invoices: null,
      state: customerPrepaidPakageK?.state,
      remainingCheckins: remainingCheckins,
    });
    checked = checked === 0 ? typCheck : checked;
  }

  for (k in sub) {
    //Load subscription
    let subK = sub[k]?.subscription;
    const checkInAvailability = isPreviousCheckinForCadence(
      subK?.lastCheckin?.date,
      subK?.plan?.checkinCadence
    );
    const mapProducts = customerPlanProducts.filter(
      (customerPlanProducts) =>
        customerPlanProducts?.plan?.uuid === subK?.plan.uuid
    );
    const numberPrest = mapProducts.length;
    const typCheck =
      checked === UNCHECKED && numberPrest > 0 && sub[k]?.checkinPossible
        ? CHECKED
        : UNCHECKED;
    myr.push({
      type: constants.TYPE_CHECKINLIST_SUBSCRIPTION,
      uuid: subK?.uuid,
      name: subK?.plan?.name,
      planUuid: subK?.plan?.uuid,
      zone: subK?.zonesQuantity,
      startDate: subK?.startDate,
      checkInAvailability: checkInAvailability,
      checkinCadence: lookUpCadenceType(subK?.plan?.checkinCadence),
      creditTypeName: null,
      checked: typCheck,
      agreementContract: subK?.agreementContract?.uuid,
      numberPrest: numberPrest,
      checkinPossible: sub[k]?.checkinPossible,
      invoices: sub[k]?.invoices,
      state: subK?.state,
      remainingCheckins: null,
    });
    checked = checked === 0 ? typCheck : checked;
  }
  //Load and queued Checkinfundingsource
  for (k in cfs) {
    let cfsK = cfs[k];
    if (
      cfsK.uuid === constants.FOUNDING_SOURCE_GIFT &&
      customerCreditList.length !== 0
    )
      continue;

    const checkinfundingsourceDefault = productsForFundingSource.find(
      (cfs) => cfs.checkinfundingsource.uuid === cfsK?.uuid
    );

    const typCheck =
      checked === UNCHECKED && !!checkinfundingsourceDefault
        ? CHECKED
        : UNCHECKED;
    myr.push({
      type: constants.TYPE_CHECKINLIST_FOUNDINGSOURCE,
      uuid: cfsK?.uuid,
      name: cfsK?.name,
      planUuid: null,
      zone: 1,
      startDate: null,
      checkInAvailability: true,
      checkinCadence: null,
      creditTypeName: null,
      checked: typCheck,
      agreementContract: null,
      numberPrest: null,
      checkinPossible: true,
      invoices: null,
    });
    checked = checked === 0 ? typCheck : checked;
  }

  return myr;
}

function searchDefaultProduct(
  initialState,
  customerPlanProducts,
  productsForFundingSource
) {
  // Seach default product for field form
  let productDefault = constants.PRODUCT_HAIR_REMOVAL;
  if (
    [
      constants.TYPE_CHECKINLIST_SUBSCRIPTION,
      constants.TYPE_CHECKINLIST_CREDIT,
    ].includes(initialState[0].radioType)
  ) {
    const planDefault = customerPlanProducts.find(
      (pln) => pln.plan.uuid === initialState[0].planUuid
    );
    if (!!planDefault) {
      productDefault = planDefault.product.uuid;
    }
  } else {
    const checkinfundingsourceDefault = productsForFundingSource.find(
      (cfs) => cfs.checkinfundingsource.uuid === initialState[0].radioUuid
    );
    if (!!checkinfundingsourceDefault) {
      productDefault = checkinfundingsourceDefault.product.uuid;
    }
  }
  return productDefault;
}

function CustomerCheckInPage() {
  const [
    createCheckInMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(MUTATION_CREATE_CHECK_IN, {
    refetchQueries: [QUERY_CHECKIN_CUSTOMER],
  });

  let { guid } = useParams();

  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);
  const navigate = useNavigate();
  const LIMIT_MAX_RECORD = 3;
  const { loading, error, data } = useQuery(QUERY_CHECKIN_CUSTOMER, {
    variables: {
      customerUuid: guid,
      checkInMaxLimit: LIMIT_MAX_RECORD,
      foundingsourceEnabled: true,
    },
  });

  if (loading) {
    return <p>Loading..</p>;
  }

  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  const currentLocation = data?.userSetting?.currentLocation;
  const isSameLocation =
    currentLocation?.uuid === data?.checkin?.location?.uuid ? true : false;

  const checkInTypeList = loadTypeCheckIn(
    data?.allCheckinFundingSource,
    data?.customerSubscriptionsEnabled,
    data?.customerCreditList,
    data?.customerPlanProducts,
    data?.productsForFundingSource,
    data?.customerPrepaidPackageList
  );

  // Build dictionary with initial state
  const CHECKED = 1;
  let initialState;
  initialState = checkInTypeList
    .filter(
      (checkInTypeList) =>
        checkInTypeList.checked === CHECKED && checkInTypeList.checked > 0
    )
    .map((checkInTypeList) => {
      return {
        radioUuid: checkInTypeList.uuid,
        radioType: checkInTypeList.type,
        planUuid: checkInTypeList.planUuid,
      };
    });

  const counterCheckIn = data?.checkInCounter;

  const checkinList = loadListCheckIn(data?.checkInCustomer);
  const customerPlanProducts = data?.customerPlanProducts;

  const productDefault = searchDefaultProduct(
    initialState,
    customerPlanProducts,
    data?.productsForFundingSource
  );

  let redirectContinueToList = null;
  if (!!shouldRedirectToList || !isSameLocation) {
    redirectContinueToList = (
      <Navigate
        to={{
          pathname: getCustomerUrl(guid),
        }}
      />
    );
  }

  function openCustomerCheckinsList(values) {
    navigate(getCustomerCheckinsListUrl(guid));
  }

  const listOpenCheckins = data?.openCheckins;
  return (
    <>
      <CustomerCheckInForm
        checkInTypeList={checkInTypeList}
        workers={data?.workersList}
        stations={data?.stationsList}
        customer={data?.customer}
        productsForFundingSource={data?.productsForFundingSource}
        counterCheckIn={counterCheckIn}
        createCheckin={createCheckInMutation}
        creating={mutationLoading}
        createError={mutationError}
        modalContinueClicked={() => {
          setShouldRedirectToList(true);
        }}
        checkinList={checkinList}
        openCustomerCheckinsList={openCustomerCheckinsList}
        customerPlanProducts={customerPlanProducts}
        initialState={initialState}
        productDefault={productDefault}
        productsPrice={data?.listProductsPriceForCheckin}
        listOpenCheckins={listOpenCheckins}
        featureFlags={data?.featureFlags}
        customerPrepaidPackageList={data?.customerPrepaidPackageList}
      />
      {redirectContinueToList}
    </>
  );
}

export default CustomerCheckInPage;
