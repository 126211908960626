import {
  ErrorMessage,
  extractApplicationError,
  extractFormErrors,
} from "./ErrorMessage";
import { FormProvider, useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import {
  customerValidationType,
  getCustomerValidationResolver,
} from "./validators";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import { CustomerInput } from "./CustomerInput";
import Form from "react-bootstrap/Form";
import FormElement from "./FormElement";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import constants from "../constants";
import {
  controlTaxiId,
  getCountryDescription,
  isUserInGroup,
  isForeignTaxId,
} from "../api/utils";
import Select from "react-select";
import { STATI } from "../dataproc/lista-stati-esteri";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getCustomerUrl } from "../api/urls";
import addBsArrowRight from "../components/AddBsArrowRight";

function ContinueModal({ onContinue, show = false }) {
  return (
    <Modal show={show} onHide={onContinue}>
      <Modal.Header closeButton>
        <Modal.Title>Aggiornamento Dati Cliente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        I dati aggiornati sono stati registrati correttamente
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onContinue}>
          Continua
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function CountrySelectionModal({
  show,
  onHide,
  onSelect,
  selectedCountry,
  taxId,
}) {
  const getForeignCountryFromTaxId = (taxId) => {
    if (!taxId || !isForeignTaxId(taxId)) return null;

    // Extract numeric code after Z
    const cfCode = taxId.substring(11, 15);

    // Find entry matching the cadastral code
    const countryEntry = STATI.find((entry) => entry[2] === cfCode);

    // Check if country was found
    if (countryEntry) {
      return {
        code: countryEntry[0], // First column: country code
        description: countryEntry[1], // Second column: description
      };
    } else {
      return null; // Return null if cfCode not found
    }
  };

  const [tempSelection, setTempSelection] = useState(() => {
    const foreignCountry = getForeignCountryFromTaxId(taxId);
    return selectedCountry || foreignCountry || null;
  });
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (show) {
      const foreignCountry = getForeignCountryFromTaxId(taxId);
      setTempSelection(selectedCountry || foreignCountry || null);
      setIsClosing(false);
    }
  }, [show, selectedCountry, taxId]);

  const handleHide = () => {
    if (!isClosing) {
      setIsClosing(true);
      setTimeout(() => {
        onHide();
        setIsClosing(false);
      }, 100);
    }
  };

  const handleConfirm = () => {
    if (!isClosing) {
      setIsClosing(true);
      setTimeout(() => {
        onSelect(tempSelection);
        onHide();
        setIsClosing(false);
      }, 100);
    }
  };

  const handleClearCountry = () => {
    if (!isClosing) {
      setIsClosing(true);
      setTimeout(() => {
        onSelect(null);
        onHide();
        setIsClosing(false);
      }, 100);
    }
  };

  const countryOptions = STATI.filter((entry) => entry[0] !== "IT")
    .map((entry) => ({
      value: {
        code: entry[0], // codice stato
        description: entry[1], // descrizione
      },
      label: entry[1], // descrizione come label
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const foreignCountry = getForeignCountryFromTaxId(taxId);
  const isCountryFromTaxId = foreignCountry !== null;

  return (
    <Modal show={show} onHide={handleHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Seleziona Nazione per il CF: {taxId}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>
            La Nazione va inserita solo per gli stranieri che non hanno il
            codice fiscale.
          </strong>
          <br />
          <strong>
            Il codice fiscale presente è stato calcolato in maniera fittizia e
            non ha alcun valore ai fini fiscali.
          </strong>
          {isCountryFromTaxId && (
            <>
              <br />
              <strong className="text-danger">
                Nazione rilevata dal codice fiscale:{" "}
                {foreignCountry.description}
              </strong>
            </>
          )}
        </p>
        <Select
          options={countryOptions}
          value={countryOptions.find(
            (option) => option.value?.code === tempSelection?.code
          )}
          onChange={(selected) => {
            setTempSelection(selected?.value || null);
          }}
          isDisabled={isCountryFromTaxId}
          isClearable
          isSearchable
          placeholder="Cerca o seleziona una nazione..."
          noOptionsMessage={() => "Nessuna nazione trovata"}
          styles={{
            menu: (base) => ({
              ...base,
              maxHeight: "400px",
            }),
            menuList: (base) => ({
              ...base,
              maxHeight: "400px",
            }),
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        {selectedCountry && (
          <Button
            variant="danger"
            onClick={handleClearCountry}
            disabled={isClosing}
          >
            Cancella Nazione
          </Button>
        )}
        <Button variant="secondary" onClick={handleHide} disabled={isClosing}>
          Annulla
        </Button>
        {!selectedCountry && (
          <Button
            variant="primary"
            onClick={handleConfirm}
            disabled={isClosing}
          >
            Conferma
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

function CustomerUpdateForm({
  data,
  updateData,
  updateCustomer,
  updateCustomerCountry,
  updating,
  updatingCountry,
  completed,
  updateError,
  modalContinueClicked = () => {},
  referralSources,
  validation = customerValidationType.RECEIVING,
  userSetting,
}) {
  const navigate = useNavigate();
  // Form hooks
  const mail =
    data?.email.lastIndexOf(constants.MISSING_EMAIL_MARKER) ===
    constants.STRING_NOT_FOUND
      ? data?.email
      : "";

  const [showCountryModal, setShowCountryModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(() => {
    if (data?.address?.country) {
      return {
        code: data.address.country,
        description: getCountryDescription(data.address.country) || "",
      };
    }
    return null;
  });

  const isManagementInsertCountry = isUserInGroup(
    userSetting,
    constants.GROUPS_MANAGEMENT_INSERT_COUNTRY
  );

  const useFormMethods = useForm({
    resolver: getCustomerValidationResolver(validation),

    defaultValues: {
      last: data?.last,
      first: data?.first,
      email: mail,
      phoneNumber: data?.phoneNumber,
      notes: data?.notes,
      taxId: !!data?.taxId ? data?.taxId : "",
      referralSource: data?.referralSource?.uuid,
      road: data?.address?.road,
      houseNumber: data?.address?.houseNumber,
      city: !!data?.address?.city ? data?.address?.city : null,
      county: !!data?.address?.county ? data?.address?.county : null,
      postcode: !!data?.address?.postcode ? data?.address?.postcode : null,
      country: data?.address?.country?.code || "",
    },
  });
  const { register, handleSubmit, errors } = useFormMethods;

  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [msgValue, setMsgValue] = useState("");

  const [countryUpdateSuccess, setCountryUpdateSuccess] = useState(false);

  const possibleErrorList = [
    extractApplicationError(updateData),
    extractFormErrors(errors),
    updateError,
  ];

  const onSubmit = async (formData) => {
    setMsgValue(""); // erase previous error

    // Check if taxId is being changed and country exists
    if (data?.taxId !== formData.taxId && data?.address?.country) {
      setMsgValue(
        <Alert variant="danger">
          Il codice Fiscale non può essere aggiornato. E' già assegnata la
          Nazione.
        </Alert>
      );
      return;
    }

    const resultTaxiId = controlTaxiId(
      formData.taxId,
      formData.first,
      formData.last
    );
    if (resultTaxiId !== "") {
      setMsgValue(<Alert variant="danger"> {resultTaxiId} </Alert>);
      return;
    }
    // Check if tax ID has been validated
    const fromTaxid = !!formData.taxId ? formData.taxId : null;
    if (data?.taxId !== fromTaxid && !formData.taxIdVerified) {
      if (!!formData.taxId) {
        setMsgValue(
          <>
            <div className="mt-4" />
            <Alert variant="danger">
              Verificare il codice fiscale prima di procedere
            </Alert>
          </>
        );
        setTimeout(() => {
          setMsgValue("");
        }, 5000); // 5 secods
        return;
      }
    }

    const customer = {
      first: formData.first,
      last: formData.last,
      taxId: !!formData.taxId ? formData.taxId : null,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      notes: formData.notes,
      referralSource: formData.referralSource,
      address: {
        road: formData.road,
        city: formData.city,
        county: formData.county,
        postcode: formData.postcode,
        houseNumber: formData.houseNumber,
        country: selectedCountry?.code,
      },
    };
    const result = await updateCustomer({
      variables: {
        customer,
        uuid: data.uuid,
      },
    });
    const resultErrors =
      result?.errors || extractApplicationError(result?.data);
    if (!resultErrors) {
      setUpdateSuccess(true);
      setShowModal(true);
    }
  };

  const buttonSpinner = <Spinner animation="border" variant="light" />;

  const updatingSuccess = <Alert variant="success"> Cliente aggiornato</Alert>;

  const handleCountryUpdate = async (newCountry) => {
    if (newCountry?.code === selectedCountry?.code) {
      setShowCountryModal(false);
      return;
    }
    try {
      await updateCustomerCountry({
        variables: {
          uuid: data.uuid,
          country: newCountry?.code || "",
        },
      });
      setSelectedCountry(newCountry);
      setShowCountryModal(false);
      setCountryUpdateSuccess(true);
      setTimeout(() => {
        setCountryUpdateSuccess(false);
      }, 5000);
    } catch (error) {
      console.error("Error updating country:", error);
    }
  };

  const isForeign = data?.taxId ? isForeignTaxId(data.taxId) : false;

  return (
    <>
      <FormProvider {...useFormMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={10}>
              <h4 className="text-center"> Aggiornamento Dati Cliente </h4>
            </Col>
            <Col xs={1}>
              <ButtonGroup>
                <Col>
                  <Button variant="primary" type="submit" disabled={updating}>
                    {updating ? buttonSpinner : "Aggiorna"}
                  </Button>
                </Col>
              </ButtonGroup>
            </Col>
            <Col xs={1}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-primary"
                  id="dropdown-menu-align-right"
                >
                  Azioni
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => navigate(getCustomerUrl(data.uuid))}
                  >
                    {addBsArrowRight("Cliente")}
                  </Dropdown.Item>
                  {isManagementInsertCountry && (
                    <Dropdown.Item onClick={() => setShowCountryModal(true)}>
                      {addBsArrowRight("Aggiorna Nazione")}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <CustomerInput fullInfo={true} />

          <Row>
            <Form.Group as={Col} xs={4}>
              <Form.Label>Nazione</Form.Label>
              <Form.Control
                value={selectedCountry?.description || ""}
                readOnly
                placeholder={
                  isManagementInsertCountry && isForeign
                    ? "Selezionare dal menu Azioni"
                    : ""
                }
                disabled={true}
              />
            </Form.Group>
          </Row>

          {isManagementInsertCountry && (
            <CountrySelectionModal
              show={showCountryModal}
              onHide={() => setShowCountryModal(false)}
              onSelect={handleCountryUpdate}
              selectedCountry={selectedCountry}
              taxId={data?.taxId || ""}
            />
          )}

          <Row>
            <Form.Group as={Col} xs={6} controlId="notes">
              <Form.Label>Note</Form.Label>
              <Form.Control as="textarea" rows="1" {...register("notes")} />
            </Form.Group>

            <Form.Group as={Col} xs={6} controlId="referralSource">
              <FormElement
                label="Fonte Contatto"
                name="referralSource"
                register={register}
                errors={errors}
                controlOptions={{ as: "select" }}
              >
                {referralSources.map((p) => (
                  <option key={p.uuid} value={p.uuid}>
                    {p.name}
                  </option>
                ))}
              </FormElement>
            </Form.Group>
          </Row>

          <Row>
            <ErrorMessage errors={possibleErrorList} />
          </Row>
          <Row>{updateSuccess ? updatingSuccess : null}</Row>
          <Row>{msgValue !== "" ? msgValue : null}</Row>
          {countryUpdateSuccess && (
            <Row className="mt-3">
              <Col>
                <Alert variant="success" className="text-center">
                  Nazione aggiornata con successo
                </Alert>
              </Col>
            </Row>
          )}
        </Form>
      </FormProvider>

      <ContinueModal
        show={showModal}
        onContinue={() => {
          setShowModal(false);
          modalContinueClicked();
        }}
      />
    </>
  );
}
export default CustomerUpdateForm;
